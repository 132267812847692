import React from 'react'
import PropTypes from 'prop-types'
import FormBuilder from '@/component/Common/FormBuilder'
import createFormSubmissionContainer from '../../Common/FormBuilder/form-submission'
import buildSchema from './schema'

const PhotoFormWidget = ({ photoForm, section }) => {
  const FormSubmissionContainer = createFormSubmissionContainer({
    Component: (props) => <FormBuilder {...props} />,
    submitUrl: `/api/submit-photo-form/`,
    buildSchema,
    form: photoForm,
    section
  })
  return <FormSubmissionContainer />
}

PhotoFormWidget.propTypes = {
  section: PropTypes.object,
  photoForm: PropTypes.array
}

export default PhotoFormWidget
