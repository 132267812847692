import React from 'react'
import PropTypes from 'prop-types'

import StandardCheckField from '../standard/StandardCheckField'
import StandardControlField from '../standard/StandardControlField'
import StandardTextField from '../standard/StandardTextField'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import StandardUploadField from '../standard/StandardUploadField'

const fieldMapper = {
  inlineCheckbox: 'check',
  checkbox: 'check',
  textArea: 'multitext',
  file: 'file'
}

const Field = (props) => {
  const { type, error, label, required, name } = props

  const componentMap = {
    checkbox: () => <StandardCheckField {...props} checkType="checkbox" />,
    radio: () => <StandardCheckField {...props} checkType="radio" />,
    select: () => <StandardControlField {...props} />,
    file: () => <StandardUploadField {...props} />
  }

  return (
    <>
      <FieldTemplate
        template={fieldMapper[type]}
        label={label}
        controlName={name}
        required={required}
        status={error && 'error'}
        feedback={error}
      >
        {componentMap[type] ? (
          componentMap[type]()
        ) : (
          <StandardTextField {...props} />
        )}
      </FieldTemplate>
    </>
  )
}

Field.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string
}

export default Field
