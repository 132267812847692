import React from 'react'
import {
  bool,
  func,
  oneOf,
  oneOfType,
  string,
  shape,
  any,
  number
} from 'prop-types'
import classNames from 'classnames'

import { useStatusContext } from '../../Context/StatusContext'
import styles from './UploadControl.module.scss'

const UploadControl = ({
  className,
  controlRef,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  required,
  status,
  tabIndex
}) => {
  const contextStatus = useStatusContext()
  const derivedStatus = status || contextStatus

  const componentClassName = classNames(
    styles.UploadControl,
    derivedStatus && styles[derivedStatus],
    className
  )

  return (
    <input
      type="file"
      accept="image/png, image/jpeg"
      className={componentClassName}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={controlRef}
      required={required}
      tabIndex={tabIndex}
      aria-invalid={status === 'error' || undefined}
      aria-required={required}
    />
  )
}

UploadControl.propTypes = {
  className: string,
  controlRef: oneOfType([func, shape({ current: any })]),
  disabled: bool,
  id: string,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  placeholder: string,
  readOnly: bool,
  required: bool,
  status: oneOf(['none', 'error', 'notice', 'success', 'warning']),
  tabIndex: number
}

UploadControl.defaultProps = {
  status: 'none'
}

export default UploadControl
