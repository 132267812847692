import React from 'react'
import PropTypes from 'prop-types'

import UploadControl from '@/component/Primitive/UploadControl'

const StandardUploadField = ({
  showRequired = true,
  required,
  error,
  label,
  responses,
  handleOnChange,
  type,
  name,
  ...other
}) => {
  return (
    <>
      <UploadControl name={name} onChange={handleOnChange} />
    </>
  )
}

StandardUploadField.defaultProps = {}

StandardUploadField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  responses: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired
}

export default StandardUploadField
