const createQuery = () => `
... on PhotoFormWidget {
  photoForm {
    _id
    title
    thankyouMessage
    account
    questions {
      question
      required
      inputType
      wordLimit
      width
      responses {
        response
      }
    }
  }
}
`
export default createQuery
